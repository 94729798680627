@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #000000; */
}

button.access-simulator {
  background: #36799B;
}

.overlay-logo {
  z-index: 1000;
  width: 100%;
  position: absolute;
  bottom: 167px;
  -webkit-filter: drop-shadow(0 1px 1px rgba(0,0,0,0.7));
  filter: drop-shadow(0 1px 1px rgba(0,0,0,0.7));
}
/* @media only screen and (max-width: 410px) {
  .overlay-logo {
    bottom: 177px;
  }
} */
.overlay-logo img {
  margin: 0 auto;
  height: 20%;
  max-height: 85px;
  left: 0;
  right: 0;
}

.hud-instructions-toggle {
  z-index: 1000;
  position: absolute;
  top: 15px;
  right: 15px;
  /* border-radius: 12px; */
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 1.3em;
  font-weight: bolder;
  cursor: pointer;
  background: rgba(87,87,87,1);
  /* background: rgba(109, 124, 118, 0.9); */
  box-shadow: 0 3px 6px rgba(87,87,87,0.16), 0 3px 6px rgba(87,87,87,0.23);
  color: #fff;
}

.hud-take-picture {
  z-index: 1000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #fff;
  text-align: right;
}
.hud-take-picture .help-text {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  /* -webkit-text-stroke: 1px rgba(0,0,0,0.1); */
  font-size: 14px;
  position: absolute;
  width: 110px;
  right: 16px;
  top: -14px;
  font-weight: bold;
}
.hud-take-picture button, .hud-take-picture p {
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 0 auto;
  /* border-radius: 12px; */
  width: 45px;
  height: 45px;
  line-height: 35px;
  background: rgba(87,87,87,1);
  /* background: rgba(109, 124, 118, 0.9); */
  box-shadow: 0 3px 6px rgba(87,87,87,0.16), 0 3px 6px rgba(87,87,87,0.23);
  border: none;
  /* font-size: 1.1em; */
  font-size: 16px;
  color: #fff;
  outline: none;
  font-weight: bolder;
}
.hud-take-picture button {
  margin: 10px 0;
}
.hud-take-picture p {
  line-height: 45px;
}

.hud-instructions {
  z-index: 1000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  /* border-radius: 12px; */
  padding: 20px;
  cursor: pointer;
  width: 95%;
  max-width: 590px;
  /* background: rgba(87,87,87,1); */
  background: rgba(109, 124, 118, 0.9);
  /* box-shadow: 0 3px 6px rgba(87,87,87,0.16), 0 3px 6px rgba(87,87,87,0.23); */
  color: #fff;
}
.hud-instructions button {
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 23px auto 15px auto;
  padding: 9px 25px;
  /* border-radius: 12px; */
  border: none;
  background: #fff;
  color: #000;
  outline: none;
}
.hud-instructions .credits-link {
  position: absolute;
  right: 19px;
  bottom: 9px;
  font-size: 0.8em;
  color: #fff;
  opacity: 0.6;
  text-decoration: none;
}
.hud-instructions .credits-link:hover {
  opacity: 0.8;
}

.hud-messages {
  z-index: 1000;
  position: absolute;
  top: 100px;
  text-align: center;
  width: 400px;
  max-width: 100%;
}
.hud-messages .message {
  display: block;
  margin: 7px auto;
  /* border-radius: 12px; */
  padding: 8px 17px;
  cursor: pointer;
  background: rgba(255, 119, 0, 0.5);
  /* box-shadow: 0 3px 6px rgba(87,87,87,0.16), 0 3px 6px rgba(87,87,87,0.23); */
  color: #fff;
}

.hud-bottom {
  z-index: 1000;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 350px;
  text-align: center;
}
.hud-bottom .colors-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  justify-content: space-between;
}

.hud-bottom .color-label {
  margin: 12px 0;
  padding: 5px 15px 5px 7px;
  /* border-radius: 12px; */
  cursor: pointer;
  background: rgba(87,87,87,1);
  /* background: rgba(0,0,0,0.9); */
  /* box-shadow: 0 3px 6px rgba(87,87,87,0.16), 0 3px 6px rgba(87,87,87,0.23); */
  color: #fff;
  text-align: left;
}
.hud-bottom .color-label.selected {
  cursor: default;
  background: rgba(255,255,255,0.8);
  color: #000;
}

.hud-bottom .color-label .color-image-badge {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* box-shadow: 0 3px 6px rgba(87,87,87,0.16), 0 3px 6px rgba(87,87,87,0.23); */
  margin-right: 7px;
}

.hud-bottom .density-label {
  margin: 0 0 12px;
  /* border-radius: 12px; */
  padding: 5px 5px 5px 8px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;
  background: rgba(87, 87, 87, 0.9);
  /* box-shadow: 0 3px 6px rgba(87,87,87,0.16), 0 3px 6px rgba(87,87,87,0.23); */
  color: #fff;
  display: inline-block;
  text-align: center;
}
.hud-bottom .density-label select {
  border: unset;

  /* Arrow */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 8px auto;
  background-color: transparent;

  padding-right: 25px;
  color: #fff;
  border: 0;
  font-size: inherit;
  outline: none;
  position: relative;
}

.output_canvas {
  /* transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg); */
  cursor: default;
}

.instructions-list {
  margin: 0;
  padding: 0;
}
.instructions-list li {
  list-style: none;
}

.flash {
  height: 100%;
  width: 100%;
  background: #fff;
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 1000;
}
.flash.visible {
  opacity: 1;
  -webkit-animation: flash 0.05s;
  animation: flash 0.05s;
}
@-webkit-keyframes flash {
  90% { opacity: .3; }
  100% { opacity: 1; }
}
@keyframes flash {
  90% { opacity: .3; }
  100% { opacity: 1; }
}